import * as React from 'react';
import { Divider, List, Icon } from 'semantic-ui-react';

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <Divider fitted />
                <List>
                    <List.Item>
                        <List.Content>
                            <Icon name="building" circular />Estemity Limited
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            <Icon name="mail" circular />info@estemity.com
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            <Icon name="phone" circular />+44 (0)20 8785 2640
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            <Icon name="home" circular />Unit 15, 9 Disraeli Road, London SW15 2DR
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            Company Registration: 8348715
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            VAT Registration: 153 5276 12
                        </List.Content>
                    </List.Item>
                </List>
                <p>Copyright &copy; {new Date().getFullYear()} Estemity Limited. All rights reserved.</p>
            </section>
        );
    }
}

export default Footer;
