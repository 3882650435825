import * as React from 'react';
import { Grid,  Divider, Header, Icon } from 'semantic-ui-react';
import ServiceItem from './serviceitem';

class Service extends React.Component {
    render() {
        const bullet = <Icon name="checkmark" />;

        return (
            <section id="service">
                <Divider horizontal className="contentdivider"><h1>業務内容</h1></Divider>

                <div className="container">
                    <Grid stretched doubling relaxed="very" columns={2}>
                        <Grid.Column textAlign="center">
                            <Header as="h1" icon>
                                <Icon name="settings" />
                                    業務システム開発
                                <Header.Subheader className="servicesubheader">
                                    <p>
                                    システム開発・インフラ構築を中心に、お客様が業務上抱えている問題を分析・解決いたします。
                                    新規システム構築から既存システムの再構築・モバイル対応等幅広く対応いたします。
                                    </p>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>                
                        <Grid.Column textAlign="center">
                            <Header as="h1" icon>
                                <Icon name="shop" />
                                    B2C Webサイト開発
                                <Header.Subheader className="servicesubheader">
                                    <p>
                                    デザイン・ユーザビリティに富んだWebサイトからAirBnBの様な複雑なeCommerceに対応できるWebアプリケーション・モバイルアプリケーションを作成いたします。
                                    </p>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>                
                    </Grid>

                    <section id="development">
                        <Grid stretched>
                            <ServiceItem
                                header="Mobile Applications"
                                subheader="モバイルアプリケーション"
                                description={
                                    <div>
                                        <p>{bullet}対応OS<br/>iOS、Android、Windows Mobile</p>
                                        <p>{bullet}環境<br/>Xamarin, Cordova+React</p>
                                    </div>}
                            />
                            <ServiceItem
                                header="Web Applications"
                                subheader="ウェブアプリケーション"
                                description={
                                    <div>
                                        <p>{bullet}対応デバイス<br/>PC、モバイル端末</p>
                                        <p>{bullet}環境<br/>JavaScript(React, Angular), ASP.NET MVC, ASP.NET Core, Ruby on rails, Kendo UI, Semantic UI</p>
                                    </div>}
                            />
                            <ServiceItem
                                header="Desktop Applications"
                                subheader="デスクトップアプリケーション"
                                description={
                                    <div>
                                        <p>{bullet}対応OS<br/>Windows PC</p>
                                        <p>{bullet}環境<br/>Windows Forms (.NET)</p>
                                    </div>}
                            />
                            <ServiceItem
                                header="Microsoft Azure Cloud Consultation"
                                subheader="Microsoft Azure コンサルティング"
                                description={
                                    <div>
                                        <p>{bullet}検討・導入</p>
                                        <p>{bullet}運用</p>
                                    </div>}
                            />
                        </Grid>
                    </section>
                </div>
            </section>
        );
    }
}

export default Service;
