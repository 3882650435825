import * as React from 'react';
import { Header, Segment, Grid } from 'semantic-ui-react';

interface  ItemProps {
    header: string;
    subheader?: string;
    description: any;
}

class ServiceItem extends React.Component<ItemProps> {
    render() {
        return (
            <Grid.Column mobile={16} tablet={8} computer={4}>
                <Header as='h5' attached='top'>
                    {this.props.header}
                    <Header.Subheader>{this.props.subheader}</Header.Subheader>
                </Header>
                <Segment attached className="servicedescription">
                    {this.props.description}
                </Segment>
            </Grid.Column>
        );
    }
}

export default ServiceItem;
