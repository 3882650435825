import * as React from 'react';
import { Divider, Item } from 'semantic-ui-react';
import ProcessItem from './processitem';

class Process extends React.Component {

    contactClicked = () => {
        const element = document.getElementById("contact");
        if (element) element.scrollIntoView();        
    }
    
    render() {
        return (
            <section id="process">
                <Divider horizontal className="contentdivider"><h1>General Workflow</h1></Divider>
                <div className="containernarrow">
                    <Item.Group>
                        <ProcessItem
                            processnumber="1"
                            header="Enquiries"
                            description={<p>Please send your <button className="flat" onClick={this.contactClicked}>enquiry</button> to our team.</p>} />
                        <ProcessItem
                            processnumber="2"
                            header="Consultation, investigation and analysis"
                            description={<p>We will set up a consultation meeting to discuss your requirements. </p>} />
                        <ProcessItem
                            processnumber="3"
                            header="Proposal and Quotation"
                            description={<p>Based on the consultation, investigation and analysis of your workflow, we will make a proposal to solve the issues identified and provide you with a quotation.</p>} />
                        <ProcessItem
                            processnumber="4"
                            header="Order and Contract"
                            description={<p>If you are satisfied with our proposal, we will draw up a formal contract for your signature.</p>} />
                        <ProcessItem
                            processnumber="5"
                            header="Requirement Definition Document"
                            description={<p>We will carry out an in-depth investigation of the business operations to assess your requirements for the system and create a thorough requirement definition document.</p>} />
                        <ProcessItem
                            processnumber="6"
                            header="System Development"
                            description={<p>We will build a system that reflects your business needs utilising our extensive experience and expertise.</p>} />
                        <ProcessItem
                            processnumber="7"
                            header="Usability Testing"
                            description={<p>We will ask you to carry out the final check to see if the system meets your requirements.</p>} />
                        <ProcessItem
                            processnumber="8"
                            header="Delivery and Invoicing"
                            description={<p>We will deliver the system and ensure that the system is operational.</p>} />
                        <ProcessItem
                            processnumber="9"
                            header="Maintenance"
                            description={<p>We will provide regular maintenance to ensure safe, stable and efficient operation of the system as well as undertaking any troubleshooting required if there should be a problem.</p>} />
                    </Item.Group>
                </div>
            </section>
        );
    }
}

export default Process;