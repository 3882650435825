export default class Utils {
    static getMessage(r: any) {
        if (r.response) {
            if (r.response.data && r.response.data.message) {
                return r.response.data.message
            } else if (r.response.statusText) {
                return `${r.response.statusText} (Code: ${r.response.status})`;
            }
        }
        else
            return "";
    }
}
