import * as React from 'react';
import { Divider, Item } from 'semantic-ui-react';
import ProcessItem from './processitem';

class Process extends React.Component {

    contactClicked = () => {
        const element = document.getElementById("contact");
        if (element) element.scrollIntoView();        
    }
    
    render() {
        return (
            <section id="process">
                <Divider horizontal className="contentdivider"><h1>納品までの流れ</h1></Divider>
                <div className="containernarrow">
                    <Item.Group>
                        <ProcessItem
                            processnumber="1"
                            header="お問い合わせ・ご相談"
                            description={<p>まずは弊社に<button className="flat" onClick={this.contactClicked}>お問い合わせ・ご相談</button>ください。</p>} />
                        <ProcessItem
                            processnumber="2"
                            header="ヒヤリング・調査・分析"
                            description={<p>お客様のご要望を把握するため、お打ち合わせをさせていただきます。</p>} />
                        <ProcessItem
                            processnumber="3"
                            header="ご提案・お見積り"
                            description={<p>ヒヤリング・調査・分析の結果をもとに、御社の問題・課題を解決するためのご提案・お見積りを提出させていただきます。</p>} />
                        <ProcessItem
                            processnumber="4"
                            header="ご発注・ご契約"
                            description={<p>弊社の提案にご納得いただけましたら、正式なご発注をいただきます。</p>} />
                        <ProcessItem
                            processnumber="5"
                            header="要件定義"
                            description={<p>お客様の業務詳細をヒヤリングさせていただき、システムに求める要件をとりまとめ定義書を作成します。</p>} />
                        <ProcessItem
                            processnumber="6"
                            header="開発"
                            description={<p>豊富な経験を基に、お客様からのご要望を反映したシステム構築を行います。</p>} />
                        <ProcessItem
                            processnumber="7"
                            header="ユーザテスト"
                            description={<p>システムがお客様の要求を満たしているかどうか、お客様自身に最終確認を行っていただきます。</p>} />
                        <ProcessItem
                            processnumber="8"
                            header="納品・ご請求"
                            description={<p>システムを納品し本番稼働を開始いたします。</p>} />
                        <ProcessItem
                            processnumber="9"
                            header="保守"
                            description={<p>システムが正常に稼働できるよう定期的な運用、またトラブル発生時のサポートを行います。</p>} />
                    </Item.Group>
                </div>
            </section>
        );
    }
}

export default Process;