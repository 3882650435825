import * as React from 'react';
import { Label, Item } from 'semantic-ui-react';

interface  ItemProps {
    processnumber: string;
    header: string;
    description: any;
}

class ProcessItem extends React.Component<ItemProps> {
   render() {
        return (
            <Item>
                <Item.Content>
                    <Item.Header>
                        <Label circular color="orange" size="huge">{this.props.processnumber}</Label>&nbsp;&nbsp;
                        {this.props.header}
                    </Item.Header>
                    <Item.Description className="processdescription">
                        {this.props.description}
                    </Item.Description>
                </Item.Content>
            </Item>
        );
    }
}

export default ProcessItem;
