import * as React from 'react';
import { Grid, Segment, Header, Icon, Label } from 'semantic-ui-react';

interface  ItemProps {
    casenumber: string,
    header: string;
    subheader: string;
    issues: any;
    resolutions: any;
}

class CaseStudyItem extends React.Component<ItemProps> {
    render() {
        return (
            <div className="casestudyitem">

                <Header as='h3'>
                    <Label size="big">{this.props.casenumber}</Label>&nbsp;&nbsp;
                        {this.props.header}
                    <Header.Subheader className="casestudysubheader">
                        {this.props.subheader}
                    </Header.Subheader>
                </Header>

                <Grid stretched>
                    <Grid.Column mobile={16} tablet={16} computer={7}>
                        <Header as='h5' attached='top' inverted color="blue">TASK</Header>
                        <Segment attached padded className="casestudydescription">
                            {this.props.issues}
                        </Segment>
                    </Grid.Column>
                
                    <Grid.Column only="computer" textAlign="center" verticalAlign="middle" computer={2}>
                        <Header as="h1" icon>
                            <Icon name="arrow circle right" color="grey" size="massive" />
                        </Header>
                    </Grid.Column>
                    <Grid.Column only="tablet mobile" textAlign="center" verticalAlign="middle" mobile={16} tablet={16}>
                        <Header as="h1" icon>
                            <Icon name="arrow circle down" color="grey" size="massive" />
                        </Header>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={16} computer={7}>
                        <Header as='h5' attached='top' inverted color="orange">RESULT</Header>
                        <Segment attached padded className="casestudydescription">
                            {this.props.resolutions}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default CaseStudyItem;
