import * as React from 'react';
import { Button } from 'semantic-ui-react';

class Header extends React.Component {

    contactClicked = (event: any) => {
        const element = document.getElementById("contact");
        if (element) element.scrollIntoView();        
    }
    
    render() {
        return (
            <section id="banner">
                <div className="containerverynarrow">
                    <h2>estemity</h2>
                    <h5>DRIVING YOUR BACK OFFICE FORWARD</h5>
                    <br />
                    <p>英国・ロンドンを拠点にシステム開発を行っています。<br/>
                    日本語・英語のバイリンガル技術者が、システムの企画・設計から開発・運用保守まで幅広いサービスをご提供いたします。</p>
                    <Button basic inverted onClick={this.contactClicked}>お問い合わせ</Button>
                </div>
            </section>
        );
    }
}

export default Header;
