import * as React from 'react';
import { Divider } from 'semantic-ui-react';
import CaseStudyItem from './casestudyitem';

class CaseStudy extends React.Component {
  render() {
    return (
        <section id="casestudy">
            <Divider horizontal className="contentdivider"><h1>Project Examples</h1></Divider>
            <div className="container">
                <CaseStudyItem
                    casenumber="1"
                    header="Complaint Management System"
                    subheader="A major manufacturer’s request for the development and introduction of a system that would improve the quality management and control."
                    issues={
                        <div>
                            <p>As each office across Europe had their own method to manage complaints it was difficult for the management to establish a consistent and comparable point of reference for the whole picture.</p>
                            <p>The client wanted to introduce a comprehensive system that allows all the offices to manage complaints on the same platform.</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>The new system was introduced to all of the client’s offices, integrating all complaint data together. This allowed the staff to output statistics reports (including charts and pivot tables) with only a couple of clicks, which facilitated the management to overview the shifts and types of complaints and implement preventive measures.</p>
                            <p>As this was an on-premise web system, the introduction cost could be kept to a minimum.</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="2"
                    header="Complaint Action Management System"
                    subheader="Introduction of a system to create and manage improvement plans in response to the complaints collected in the Project Example 1"
                    issues={
                        <div>
                            <p>After the complaint data were successfully integrated thanks to the introduction of the new complaints management system (Project Example 1), the client wanted to take a step further and requested for a system to manage improvement plans in response to complaints.</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>We developed a new system that supports creation of improvement plans and visualises the progress of each case.</p>
                            <p>Each department started to record how complaints were dealt with and inspection results on the system. This allowed the staff to utilise the past data to probe the cause and consider the possible response when a similar complaint arose.</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="3"
                    header="Workflow System"
                    subheader="Introduction of a system that unifies the client’s internal approval process"
                    issues={
                        <div>
                            <p>The client wanted to systemise their internal application/approval process (e.g. for IT change control or purchase application), which had previously been done by email and oral communication, and unify and manage the data.</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>As all applications are now managed on the same system, the staff can check and refer to the application details and the progress and history of approvals.</p>
                            <p>The email notifications and reminders from the system have significantly reduced authorisers’ non-response and missing deadlines.</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="4"
                    header="ERP (Enterprise Resource Planning) System"
                    subheader="Introduction of Fully Customised ERP System"
                    issues={
                        <div>
                            <p>The client was using Excel, Word and Access to create quotations, invoices and other documents. </p>
                            <p>All documents were created independently and left unlinked.  The client did not have an established operational flow to manage them.</p>
                            <p>The client wanted to introduce a comprehensive system which would allow them to manage all documents relating to one case, from quotations to invoices, as a linked set of data.</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>We developed and introduced an ERP system that could manage all documents and data including quotations, purchase records, invoices, payment details, and receipts. The system provides a simple workflow to create documents and data, which makes it easy to check the order status, payment status of an invoice and other relevant information.</p>
                            <p>The system is also integrated with Sage (Accounting software), which has successfully reduced the workload and human errors.</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="5"
                    header="Mobile ERP System"
                    subheader="Making the ERP system (Project Example 4) available on mobile devices"
                    issues={
                        <div>
                            <p>Whilst authorisers were away, the authorisation procedures were put on hold.</p>
                            <p>The client wanted to introduce a system that would allow authorisers to check and approve applications even when they were out of the office.</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>We introduced a web application and native applications (iOS/Android) that would allow authorisers to see a list of applications, check the details and approve.</p>
                            <p>The approval procedures were no longer delayed during absences and the fluidity of the operation was improved.</p>
                            <p>The mobile applications do not require VPN connections, which could be time-consuming to establish, but use Azure AD authentication that allows safe and easy external connections.</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="6"
                    header="Customer Satisfaction Research System"
                    subheader="Systemising customer satisfaction measurement and countermeasure implementations, which would become mandatory with the adoption of a quality management system based on ISO 9001"
                    issues={
                        <div>
                            <p>The client was carrying out paper-based customer satisfaction surveys but the response rate was merely 30% even with return envelopes.</p>
                            <p>The client wanted to systemise these customer satisfaction surveys and improve the response rate and reduce the time spent on survey distribution.</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>After introduction of the new system, customers could answer the satisfaction surveys from the client’s website. The response rate has significantly improved.</p>
                            <p>In addition, the workload within the company has reduced significantly. Previously the selection of respondents and the survey distribution had been done manually.</p>
                        </div>}
                />
            </div>
        </section>
    );
  }
}

export default CaseStudy;