import * as React from 'react';
import { Divider, Button, Form, Segment, Confirm, Message, Header } from 'semantic-ui-react';
import axios from 'axios';
import Utils from '../utils';
import * as Environment from '../environment-variables';

class ContactUs extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = { name: "", email: "", enquiry: "", open: false, inprocess: false, errormsg: "", successmsg: "" };
    }

    // Input changed events
    onNameChange = (e: any) => this.setState({name: e.target.value});
    onEmailChange = (e: any) => this.setState({email: e.target.value});
    onEnquiryChange = (e: any) => this.setState({enquiry: e.target.value});

    // Confirm events
    openConfirm = () => this.setState({ open: true });
    handleCancel = () => this.setState({ open: false });
    handleConfirm = () => {
        this.setState({ open: false, inprocess: true, errormsg: "", successmsg: "" });
        this.sendEnqiry();
    }
    
    // Send email
    sendEnqiry = () : void => {
        axios.post(Environment.API_URL, {
                "enquiry": this.state.enquiry,
                "email": this.state.email,
                "name": this.state.name,
                //"token": "wKChQ8zCAN5PESaQDVtdc96Mrs",
                "sendemail": true
        })
        .then((response) => {
            if (response.status === 200) {
                if (response.data.errors) {
                    this.setState({ inprocess: false, errormsg: response.data.errors });
                } else {
                    this.setState({ name: "", email: "", enquiry: "", inprocess: false, successmsg: "送信が完了しました。 お問い合わせありがとうございました。" });
                }
            } else {
                throw Error(response.statusText);
            }
        })
        .catch((error) => {
            this.setState({ inprocess: false, errormsg: Utils.getMessage(error) });
        });
    }

    render() {
        const { open, inprocess } = this.state;
        
        return (
            <section id="contact">
                <Divider horizontal className="contentdivider"><h1>お問い合わせ</h1></Divider>

                <div className="containernarrow">
                    <Header as="h5">
                        <p>お問い合わせ・ご相談は下記のフォームにご記入の上、送信してください。お気軽にお問い合わせください。</p>
                    </Header>

                    <Segment inverted>
                        <Form inverted onSubmit={this.openConfirm} loading={inprocess}>
                            <Form.Group widths="equal">
                                <Form.Input required label="お名前 " maxLength="100" value={this.state.name} onChange={e => this.onNameChange(e)} />
                                <Form.Input required label="Email " type="email" maxLength="100" value={this.state.email} onChange={e => this.onEmailChange(e)} />
                            </Form.Group>
                            <Form.TextArea required label="お問い合わせ内容 (3000文字まで）" rows="10" maxLength="3000" value={this.state.enquiry} onChange={e => this.onEnquiryChange(e)} />

                            {
                                this.state.errormsg ? (
                                    <Message negative>
                                        <p>{this.state.errormsg}</p>
                                    </Message>                        
                                ) : null
                            }

                            {
                                this.state.successmsg ? (
                                    <Message positive>
                                        <p>{this.state.successmsg}</p>
                                    </Message>                        
                                ) : null
                            }

                            <Button inverted>送信</Button>
                            <Confirm
                                header="送信確認"
                                content="問い合わせを送信します。よろしいですか?"
                                cancelButton="キャンセル"
                                confirmButton="送信"
                                open={open}
                                onCancel={this.handleCancel}
                                onConfirm={this.handleConfirm}
                            />
                        </Form>
                    </Segment>
                </div>
            </section>
        );
    }
}

export default ContactUs;