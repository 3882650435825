import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import './site.css';
import Main from './en/main';
import MainJp from './jp/main';

ReactDOM.render(
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/jp" component={MainJp} />
        </Switch>
      </BrowserRouter>,
    </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
