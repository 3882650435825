import * as React from 'react';
import Header from './header';
import Service from './service';
import CaseStudy from './casestudy';
import ContactUs from './contactus';
import Process from './process';
import Footer from '../footer';

class Main extends React.Component {
    render() {
        return (
            <div id="page-wrapper">
                <Header />
                <Service />
                <CaseStudy />
                <Process />
                <ContactUs />
                <Footer />
            </div>
        );
    }
}

export default Main;
