import * as React from 'react';
import { Button } from 'semantic-ui-react';

class Header extends React.Component {

    contactClicked = (event: any) => {
        const element = document.getElementById("contact");
        if (element) element.scrollIntoView();        
    }
    
    render() {
        return (
            <section id="banner">
                <div className="containerverynarrow">
                    <h2>estemity</h2>
                    <h5>DRIVING YOUR BACK OFFICE FORWARD</h5>
                    <br />
                    <p>Estemity is a London-based company that provides a comprehensive system development solution that specifically meets the needs and requirements of your business.</p>
                    <p>Our bilingual, English-<a href="https://estemity.com/jp" style={{color: "inherit"}}>Japanese</a>, IT experts provide a complete end-to-end service, from initial planning and designing through to development, management and maintenance of the system.</p>
                    <Button basic inverted onClick={this.contactClicked}>Contact Us</Button>
                </div>
            </section>
        );
    }
}

export default Header;
