import * as React from 'react';
import { Grid,  Divider, Header, Icon } from 'semantic-ui-react';
import ServiceItem from './serviceitem';

class Service extends React.Component {
    render() {
        const bullet = <Icon name="checkmark" />;

        return (
            <section id="service">
                <Divider horizontal className="contentdivider"><h1>Our Services</h1></Divider>

                <div className="container">
                    <Grid stretched doubling relaxed="very" columns={2}>
                        <Grid.Column textAlign="center">
                            <Header as="h1" icon>
                                <Icon name="settings" />
                                    Business System Development
                                <Header.Subheader className="servicesubheader">
                                    <p>
                                    We will analyse your business system challenges and bring our wealth of knowledge in system development and IT infrastructure construction to define the best solution. We can respond to a broad range of requests from developing a new system to rebuilding your existing system and making your system mobile compatible.
                                    </p>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>                
                        <Grid.Column textAlign="center">
                            <Header as="h1" icon>
                                <Icon name="shop" />
                                    B2C Websites Development
                                <Header.Subheader className="servicesubheader">
                                    <p>
                                    We create websites with superior design and usability as well as web/mobile applications that can respond to highly complex eCommerce demands similar to those used by cutting edge companies like AirBnB.
                                    </p>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>                
                    </Grid>

                    <section id="development">
                        <Grid stretched>
                            <ServiceItem
                                header="Mobile Applications"
                                description={
                                    <div>
                                        <p>{bullet}Compatible OSs<br/>iOS、Android、Windows Mobile</p>
                                        <p>{bullet}Environments<br/>Xamarin, Cordova+React</p>
                                    </div>}
                            />
                            <ServiceItem
                                header="Web Applications"
                                description={
                                    <div>
                                        <p>{bullet}Compatible Devices<br/>PC, mobile devices</p>
                                        <p>{bullet}Environments<br/>JavaScript(React, Angular), ASP.NET MVC, ASP.NET Core, Ruby on rails, Kendo UI, Semantic UI</p>
                                    </div>}
                            />
                            <ServiceItem
                                header="Desktop Applications"
                                description={
                                    <div>
                                        <p>{bullet}Compatible OSs<br/>Windows PC</p>
                                        <p>{bullet}Environment<br/>Windows Forms (.NET)</p>
                                    </div>}
                            />
                            <ServiceItem
                                header="Microsoft Azure Cloud Consultation"
                                description={
                                    <div>
                                        <p>{bullet}Consultation and introduction</p>
                                        <p>{bullet}Management</p>
                                    </div>}
                            />
                        </Grid>
                    </section>
                </div>
            </section>
        );
    }
}

export default Service;
