import * as React from 'react';
import { Divider } from 'semantic-ui-react';
import CaseStudyItem from './casestudyitem';

class CaseStudy extends React.Component {
  render() {
    return (
        <section id="casestudy">
            <Divider horizontal className="contentdivider"><h1>開発事例</h1></Divider>
            <div className="container">
                <CaseStudyItem
                    casenumber="1"
                    header="クレーム履歴・管理システム"
                    subheader="製造系大手A社様のご依頼、品質管理・向上を目的とするシステムの導入"
                    issues={
                        <div>
                            <p>欧州各拠点にて、個別に独自の方法でクレームを管理しており、マネジメントが全体像を把握し難い。</p>
                            <p>各拠点が同一システム上でクレームを管理できるよう統合システムを導入したい。</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>各拠点にシステムを導入後、データの一元化が実現。マネジメントへの統計レポート（チャート、ピボット等）が数クリックで出力可能となり、クレームのトレンドを把握・対策することが容易となった。</p>
                            <p>オンプレミスWeb システムだったので、導入時のコストは最小限に抑えられた。</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="2"
                    header="アクション管理システム"
                    subheader="開発事例 1 のクレームに対する改善プランの作成・管理を目的とするシステムの導入"
                    issues={
                        <div>
                            <p>開発事例 1 にてシステムを導入した結果、クレームデータの一元化には成功したが、次のステップとして各クレームに対する改善プランをシステム上で管理したい。</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>システム導入後、クレームへの対応策の作成およびその進捗の見える化に成功。</p>
                            <p>また各部署の対応内容・検査結果等をシステムに記録することで、同様のクレームが発生した場合に過去のデータから原因や対応方法を検討することが可能になった。</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="3"
                    header="Workflowシステム"
                    subheader="社内の承認プロセスを一元化するシステムの導入"
                    issues={
                        <div>
                            <p>社内ITのチェンジ・コントロールや購買申請等、メールや口頭で行われている申請・承認プロセスをシステム化しデータを一元管理したい。</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>システム導入後、すべての申請が一元管理されたことにより、いつでも申請内容・承認履歴・進捗がシステム上で確認できるようになった。</p>
                            <p>また、承認者へのメール通知機能により、対応忘れや漏れが減少した。</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="4"
                    header="ERPシステム"
                    subheader="完全カスタマイズERPシステムの導入"
                    issues={
                        <div>
                            <p>Excel、Word、Accessを利用して見積や請求書を作成しているが、各文書に紐づけがなく業務フローが確立していない。</p>
                            <p>見積作成から請求書発行までの一連の文書を紐づいたデータとして管理できる統合システムを導入したい。</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>見積、購買、請求書、支払い・受領の全ての文書・データを管理できるERPシステムを構築・導入。フローに沿って文書・データを作成していく仕組みになっているので、受注ステータスや請求書の支払い状況等を確認することが容易になった。</p>
                            <p>また、Sage（会計システム）と連動させることで会計の手間とヒューマンエラーをなくすことに成功。</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="5"
                    header="ERPシステムモバイル対応"
                    subheader="開発事例 4 ERPシステムのモバイル端末対応"
                    issues={
                        <div>
                            <p>承認者が外出中の場合、各種申請の承認処理が滞ってしまう。</p>
                            <p>外出時、出張時にもモバイル端末から申請内容を確認・承認できるシステムを導入したい。</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>承認待ち申請データの一覧を表示し、詳細の確認・承認ができる、WebアプリとNativeアプリ（iOS・Android）を導入。</p>
                            <p>承認処理の滞りは解消され、業務が円滑に行えるようになった。</p>
                            <p>手間のかかるVPN接続等は利用せず、Azure AD認証で安全かつ簡単に社外からシステムに接続することが可能になっている。</p>
                        </div>}
                />
                <CaseStudyItem
                    casenumber="6"
                    header="顧客満足度調査システム"
                    subheader="ISO9001 ベースの品質マネジメント・システムにて、ほぼ必須となる顧客満足度の測定と対策をシステム化"
                    issues={
                        <div>
                            <p>紙ベースでの顧客度満足調査を行っているが、返信用封筒を同封するも回収率が30％ほどである。</p>
                            <p>この顧客度満足調査をシステム化し、回収率の向上および社内の発送作業にかかる時間を短縮したい。</p>
                        </div>}
                    resolutions={
                        <div>
                            <p>システム導入後、お客様がウェブページから満足度を回答することができるようになり、回収率は大きく向上。</p>
                            <p>また社内においても、今まで手作業で行っていた対象顧客の抽出および発送作業がシステムの導入により簡略化され、作業効率が大きく改善された。</p>
                        </div>}
                />
            </div>
        </section>
    );
  }
}

export default CaseStudy;